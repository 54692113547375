import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_create_api_key_header"
}
const _hoisted_5 = { class: "me-0" }
const _hoisted_6 = {
  class: "btn btn-sm btn-icon btn-bg-light btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2 svg-icon-gray-500" }
const _hoisted_8 = { class: "modal-body scroll-y px-8 px-lg-8 pt-8 pb-8" }
const _hoisted_9 = { class: "modal-footer flex-end" }
const _hoisted_10 = {
  type: "reset",
  class: "btn btn-white me-3",
  "data-bs-dismiss": "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ToolbarActions = _resolveComponent("ToolbarActions")!
  const _component_Table = _resolveComponent("Table")!
  const _component_ModalT = _resolveComponent("ModalT")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "modal fade",
      id: _ctx.modalId,
      ref: "modalRef",
      tabindex: "-1",
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.t('titles.'+ _ctx.componentTitle)), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, [
                  _createVNode(_component_Icon, { name: "Dots.svg" })
                ])
              ]),
              _createVNode(_component_ToolbarActions, {
                title: "Options",
                menu: { 
                create: { label: "Create", target: "#es_modal_products_product_specifications_specification_options" },
              },
                onReload: _ctx.reloadComponent
              }, null, 8, ["onReload"])
            ])
          ]),
          _createElementVNode("form", null, [
            _createElementVNode("div", _hoisted_8, [
              (_ctx.componentData.status)
                ? (_openBlock(), _createBlock(_component_Table, {
                    key: 0,
                    "processing-data": _ctx.componentData,
                    onReload: _ctx.reloadComponent
                  }, null, 8, ["processing-data", "onReload"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.t("buttons.Cancel")), 1)
            ])
          ])
        ])), [
          [_directive_loading, _ctx.componentLoading]
        ])
      ])
    ], 8, _hoisted_1),
    (_openBlock(), _createBlock(_component_ModalT, {
      key: _ctx.renderAmount,
      onReload: _ctx.reloadComponent,
      componentName: "ProductsProductSpecificationsSpecificationOptionsCreateForm",
      componentTitle: "Option",
      displayMethod: "new",
      componentType: "form",
      dispatchActions: { init: "PRODUCT_SPECIFICATION_OPTIONS", save: "PRODUCT_SPECIFICATION_OPTION_RELATION_CREATE" , params: { init: true, lines: [ { key: "product_id", value: _ctx.currentId }, { key: "product_specification_id", value: _ctx.renderId } ] } },
      componentEmit: { reload: true },
      componentReload: false,
      componentRedirect: { init: false, data: "", path: "item_id"},
      currentData: "allProductSpecificationOptions",
      componentFilter: false,
      modalId: "es_modal_products_product_specifications_specification_options",
      modalIdPrevious: { status: true, id: _ctx.modalId},
      currentId: _ctx.currentId,
      routeId: _ctx.routeId
    }, null, 8, ["onReload", "dispatchActions", "modalIdPrevious", "currentId", "routeId"]))
  ], 64))
}